import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CartFooter, CartProducts, CartTableHead, CartWrapper, ErrorButtonWrap, InnerButtonWrap } from '../../../Elements/cart/index.styled'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CartItem from '../../../Elements/cart/CartItem'
import Button from '../../../Elements/buttons/Button'
import { getNeededDateFormat } from '../../../../helper'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { usePrevious } from '../../../../hooks/usePrevious'
import { selectFormattedCurrency } from '../../../../redux/selector/system'
import Tooltip from '../../../Elements/tooltip'
import ButtonIcon from '../../../../img/svg/button'
import { selectCouponInCart, selectHasUnpaidOrderInCart, selectIsChangedUnpaidOrder } from '../../../../redux/selector/cart'
import CartGiftCouponPayment from '../../../Elements/cart/CartItem/CartGiftCouponPayment'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'
import CartTotals from '../../../Elements/cart/CartTotals'
import { checkSavedCartItemsRemoveAbility } from '../../../../helper/unpaidOrder'
import ActionModal from '../../../Elements/modal/ActionModal'
import { setDeliveryCartItems, setDeliveryCartOrder } from '../../../../redux/action/deliveryCart'

const EVENT_Z_INDEX = 93

const Cart = ({
	showTotal, items, itemsAndDiscountTotal, itemsTotal, itemFocusIndex, totalPrice,
	zIndex, setFocus, incrementQuantity, dropProduct, dropTopping, handleOrderProcessing, navigateBack,
	removeRemark, addProduct, removeCoupon, hasGiftCoupon, removeGiftCoupon, giftCouponFocused,
}) => {
	const { t } = useTranslation()
	const [touchPad] = useLocalStorage('touchPad', false)
	const isOrder = useSelector(store => !!store.deliveryCart.order)
	const preOrderDate = useSelector(store => store.deliveryCart.preOrderDate)
	const maxDistance = useSelector(store => store.deliveryCart.maxDistance)
	const showDeliveryConfigError = useSelector(store => store.deliveryCart.showDeliveryConfigError)
	const customerDistance = useSelector(store => store.customerStore.geoData?.distance)
	const minimumPrice = useSelector(store => store.deliveryCart.minimumPrice)
	const orderType = useSelector(store => store.deliveryCart.orderType)
	const coupon = useSelector(selectCouponInCart('DELIVERY'))
	const giftCoupons = useSelector(store => store.deliveryCart.giftCoupons)
	const couponMinOrderAmount = coupon?.initialProduct?.minOrderAmount
	const cartRef = useRef()
	const prevItems = usePrevious(items)
	const isMobile = useWindowBreakPoint(theme.point720)
	const dispatch = useDispatch()

	const deliveryMinPriceFormatted = useSelector(selectFormattedCurrency(minimumPrice))
	const couponMinOrderAmountFormatted = useSelector(selectFormattedCurrency(couponMinOrderAmount))
	const customerDistanceKm = customerDistance && (customerDistance / 1000.0).toFixed(1)
	const isDelivery = orderType === 'DELIVERY'

	const isUnpaidOrder = useSelector(selectHasUnpaidOrderInCart('DELIVERY'))
	const isChangedUnpaidOrder = useSelector(selectIsChangedUnpaidOrder('DELIVERY'))
	const isDisabledSendBtn = isUnpaidOrder && !isChangedUnpaidOrder

	const [backModalIsOpen, setBackModalIsOpen] = useState(false)

	useEffect(() => {
		if (items?.length > prevItems?.length) {
			cartRef.current.scrollTop = 100000
		}
	}, [items]) // eslint-disable-line

	const isCouponMinPriceUpperDeliveryMinPrice = minimumPrice < couponMinOrderAmount
	const minimumPriceToShow = isCouponMinPriceUpperDeliveryMinPrice ? couponMinOrderAmountFormatted : deliveryMinPriceFormatted

	const minPriceError = isCouponMinPriceUpperDeliveryMinPrice ? (!isOrder && couponMinOrderAmount !== 0 && itemsTotal < couponMinOrderAmount) : (isDelivery && itemsAndDiscountTotal < minimumPrice)
	const deliveryConfigError = isDelivery && showDeliveryConfigError
	const maxDistanceError = deliveryConfigError && maxDistance !== null && customerDistanceKm > maxDistance

	const showGiftCouponError = items.length <= 0 && giftCoupons.length > 0
	const giftCouponErrorTooltip = t('Cart.tooltip.gift_coupon_price_error')

	const isErrorButtonText = minPriceError || deliveryConfigError || maxDistanceError || showGiftCouponError

	const printButtonText = preOrderDate ? `${getNeededDateFormat(preOrderDate, 'dd.MM.y H:mm')}` : t('Cart.Buttons.print')

	const handleNavigateBack =  useCallback(() => {
		dispatch(setDeliveryCartItems([]))
		dispatch(setDeliveryCartOrder(null))
		navigateBack()
	}, [dispatch, navigateBack])

	const handleBackClick = useCallback(() => {
		if (isChangedUnpaidOrder) {
			setBackModalIsOpen(true)
		} else {
			handleNavigateBack()
		}
	}, [handleNavigateBack, isChangedUnpaidOrder])

	const minPriceErrorText =
		<ErrorButtonWrap>{printButtonText} <br /> (min. {minimumPriceToShow})</ErrorButtonWrap>
	const deliveryConfigErrorText =
		<ErrorButtonWrap>
			<InnerButtonWrap className={!maxDistanceError && 'nowrap'}>
				{printButtonText}
				<ButtonIcon icon="warning" />
				{maxDistanceError && <>Max {maxDistance}km</>}
			</InnerButtonWrap>
		</ErrorButtonWrap>

	const minPriceErrorTooltipText = isCouponMinPriceUpperDeliveryMinPrice ? t('Cart.tooltip.coupon_min_price_error', { minPrice: couponMinOrderAmountFormatted }) : t('Cart.tooltip.min_price_error', { minPrice: deliveryMinPriceFormatted })
	const deliveryConfigErrorTooltipText = maxDistanceError ? t('Cart.tooltip.max_distance_error', { customerDistance: customerDistanceKm, maxDistance }) : t('Cart.tooltip.delivery_config_error')

	return (
		<>
			<CartWrapper className="cart-col">
				<CartTableHead className={touchPad ? 'touchPad' : ''}>
					<div>ID</div>
					<div>{t('Cart.name')}</div>
					<div>{t('Cart.price')}</div>
					<div>{t('Cart.total')}</div>
				</CartTableHead>
				<CartProducts ref={cartRef}>
					{items?.map((item, itemIndex, arr) => {
						const savedItemsRemoveAbility = isUnpaidOrder ? checkSavedCartItemsRemoveAbility(arr, item) : true
						return <CartItem key={itemIndex}
											active={itemIndex === itemFocusIndex}
											item={item}
											removeAbility={savedItemsRemoveAbility}
											savedOrder={!!item.itemPositionId && isUnpaidOrder}
											onClick={() => setFocus(itemIndex, item)}
											onClickPlus={event => incrementQuantity(event, item, itemIndex)}
											onClickMinus={event => dropProduct(event, itemIndex)}
											onClickToppingMinus={(event, subItemIndex) => dropTopping(event, itemIndex, subItemIndex)}
											handleRemoveRemark={event => removeRemark(event, itemIndex)}
											handleRemoveCoupon={event => removeCoupon(event)} />})}
					{giftCoupons.length > 0 &&
						giftCoupons?.map((item, couponIndex) =>
							<CartGiftCouponPayment coupon={item}
																		 key={couponIndex}
																		 active={couponIndex === giftCouponFocused}
																		 handleRemoveCoupon={event => removeGiftCoupon(event, item.id)} />)}
				</CartProducts>
				{showTotal &&
					<CartTotals {...{ totalPrice, type: 'DELIVERY', giftCoupons, orderType, previousIndex: 6 }} />}
				<CartFooter>
					<Button name="back"
									icon="back"
									text={t('buttons.controls.back')}
									size={isMobile ? null : 'x-big'}
									keyButton="F4"
									zIndex={zIndex}
									onClick={isUnpaidOrder ? handleBackClick : navigateBack} />
					<Tooltip isShow={isErrorButtonText} text={showGiftCouponError ? giftCouponErrorTooltip : minPriceError ? minPriceErrorTooltipText : deliveryConfigErrorTooltipText} position="top" margin="4">
						<Button name="send"
										icon={preOrderDate ? 'time' : 'print'}
										size={isMobile ? null : 'x-big'}
										text={isErrorButtonText ? (minPriceError ? minPriceErrorText : deliveryConfigErrorText) : printButtonText}
										keyButton="F2"
										color={isErrorButtonText ? 'red' : 'green'}
										zIndex={zIndex}
										onClick={handleOrderProcessing}
										disabled={isDisabledSendBtn} />
					</Tooltip>
				</CartFooter>
			</CartWrapper>

			<ActionModal modalIsOpen={backModalIsOpen}
									 accept={handleNavigateBack}
									 cancel={() => setBackModalIsOpen(false)}
									 close={() => setBackModalIsOpen(false)}
									 modalTitle={t('Cart.ActionModal.title')}
									 modalBody={t('Cart.ActionModal.body')}
									 zIndex={EVENT_Z_INDEX} />
		</>
	)
}

Cart.displayName = 'Cart'
export default Cart
