import React from 'react'
import MainIcon from '../../../../img/svg/main'
import { TableData, TableDataContent, TableDataIconWrap, TableDataNumber, TableDataPrice, TableDataPriceWrap, TableDataWaiter, TableDataWaiterWrap } from '../SVGTables/index.styled'
import Currency from '../../../Elements/currency'
import { RestTableWaiter, RestTableWaiterWrap } from '../../../Restaurant/areas/tables/table/index.styled'
import { useTranslation } from 'react-i18next'

const RestTableComponent = React.memo(({
	data, i, handleObjClick = () => {
	}, isQRCode,
	isSettingsArea, isSettingsForm,
	totalPrice, uuid, showWaiter, processedBy, tableWaiterCall, isReadyFood
}) => {
	const { t } = useTranslation()
	const layoutData = data?.formType.toLowerCase() + ' '
	const showExample = isSettingsForm
	const showIcon = isSettingsArea || isQRCode
	const isVertical = data.width < data.height
	const layout =  (isQRCode ? 'qr-code ' : '') + (showIcon ? 'icon ' : '') + (isVertical ? 'vertical ' : '')

	return (
		<TableData className={layoutData + layout} id={uuid}>
			<TableDataContent className={layout}>
				<TableDataNumber className={layout + ' number '}>{data?.number}</TableDataNumber>
				{showExample && <TableDataPriceWrap><TableDataPrice>0.00€</TableDataPrice></TableDataPriceWrap>}
				{!!totalPrice && !isReadyFood && <TableDataPriceWrap>
					<TableDataPrice className={layout + ' total-price'}><Currency>{totalPrice}</Currency>
					</TableDataPrice></TableDataPriceWrap>}
				{isReadyFood && <TableDataPriceWrap>
					<TableDataPrice className={layout + ' total-price readyFood'}>
						{t('restaurant.readyFood')}
					</TableDataPrice></TableDataPriceWrap>}
				{showIcon && <TableDataIconWrap onClick={() => handleObjClick(data?.id, 'table')}
																				onGotPointerCapture={() => handleObjClick(data?.id, 'table')}
																				className={layout}
																				data-testid={`table_icon_${i}`}>
					<MainIcon icon={isQRCode ? 'qr-code' : 'restaurant_settings'} />
				</TableDataIconWrap>}
			</TableDataContent>
			{showExample && <TableDataWaiterWrap><TableDataWaiter>I. Wilhelm</TableDataWaiter></TableDataWaiterWrap>}
			{showWaiter && <TableDataWaiterWrap className={layoutData + layout}><TableDataWaiter data-testid="table-waiter" className={layout}>{processedBy}</TableDataWaiter></TableDataWaiterWrap>}
			{tableWaiterCall && (
				<RestTableWaiterWrap className="waiter-call" data-testid="table-waiter-call">
					<MainIcon icon="waiter-call" />
					<RestTableWaiter>{t('restaurant.waiterCall')} {processedBy}</RestTableWaiter>
				</RestTableWaiterWrap>
			)}
		</TableData>

	)
})
RestTableComponent.displayName = 'RestTableComponent'
export default RestTableComponent
