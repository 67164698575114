import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsNeededZIndex } from '../../../../../redux/selector/system'
import KeyBindings from '../../../keybindings'
import CustomModal from '../../CustomModal'
import Order from '../Order'
import Button from '../../../buttons/Button'
import { useTranslation } from 'react-i18next'
import { printOrderThunk } from '../../../../../redux/thunk/order'
import { ButtonsWrap } from './index.styled'

const EVENT_Z_INDEX = 14

const OrderDataModal = React.memo(({ order, modalIsOpen, close }) => {
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const isDeliveryByPlatform = order?.orderType === 'DELIVERY_BY_PLATFORM'
	const updatedDeliveryByPlatform = !!(order?.orderType === 'DELIVERY' && order?.deliveryMetaData?.partner?.type)
	const isPlatformDriverOrder = isDeliveryByPlatform || updatedDeliveryByPlatform
	const isOrderOfGiftCoupon = order?.items?.[0]?.type === 'GIFT_COUPON'
	const title = (isOrderOfGiftCoupon ? t('Cart.GiftVoucher.title') : isPlatformDriverOrder ? t('Filter.type.DELIVERY') : t(`Filter.type.${order?.orderType}`))

	const showPrintButton = useMemo(() => {
		if (!order) return false
		return !['NOT_ACCEPTED', 'DENIED', 'NOT_REGISTERED'].includes(order.status?.status)
	}, [order])

	const handlePrintOrder = useCallback(() => {
		if (!order.orderId) return
		dispatch(printOrderThunk(order.orderId, false))
	}, [dispatch, order?.orderId])

	return (
		<>
			<CustomModal isOpen={modalIsOpen}
									 id="order-data-modal"
									 close={close}
									 title={title}
									 zIndex={EVENT_Z_INDEX}
									 returnZIndex={1}
									 titleUpperCase={true}
									 disableBackButton={true}
									 size="height-scroll small"
									 button={
										<ButtonsWrap>
											<Button icon="clear" keyButton="F3" text={t('buttons.controls.cancel_1')}	onClick={close} className="back"/>
											{showPrintButton &&
												<Button name="print" icon="print" text={t('order.modal.print_order')} color="green"
																keyButton="F2" zIndex={EVENT_Z_INDEX} onClick={handlePrintOrder} />}
										</ButtonsWrap>
										}>
				{order && <Order order={order} />}
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings escape={close} />}
		</>
	)
})

OrderDataModal.displayName = 'OrderDataModal'
export default OrderDataModal
