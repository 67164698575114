export const SET_PARTNER_DEALER_COMPANIES_FILTER = 'SET_PARTNER_DEALER_COMPANIES_FILTER'
export const SET_PARTNER_COMPANIES_FILTER = 'SET_PARTNER_COMPANIES_FILTER'
export const SET_PARTNER_COMPANIES = 'SET_PARTNER_COMPANIES'
export const APPEND_PARTNER_COMPANIES = 'APPEND_PARTNER_COMPANIES'
export const SET_PARTNER_COMPANIES_TOTALS = 'SET_PARTNER_COMPANIES_TOTALS'
export const SET_PARTNER_COMPANIES_LENGTH = 'SET_PARTNER_COMPANIES_LENGTH'
export const SET_PARTNER_COMPANIES_IS_LOADING = 'SET_PARTNER_COMPANIES_IS_LOADING'
export const SET_PARTNER_FILTER = 'SET_PARTNER_FILTER'
export const SET_PARTNER_CLIENTS_REPORTS_MODAL = 'SET_PARTNER_CLIENTS_REPORTS_MODAL'
export const SET_PARTNER_DEALER_PROVISION = 'SET_PARTNER_DEALER_PROVISION'
export const SET_PARTNER_CLIENTS_REPORTS = 'SET_PARTNER_CLIENTS_REPORTS'
export const APPEND_PARTNER_CLIENTS_REPORTS = 'APPEND_PARTNER_CLIENTS_REPORTS'
export const SET_PARTNER_CLIENTS_REPORTS_LENGTH = 'SET_PARTNER_CLIENTS_REPORTS_LENGTH'
export const SET_PARTNER_DEALER_REPORT_DATA = 'SET_PARTNER_DEALER_REPORT_DATA'
export const SET_PARTNER_DEALER_REPORT_DATE_FROM = 'SET_PARTNER_DEALER_REPORT_DATE_FROM'
export const SET_PARTNER_DEALER_REPORT_DATE_TO = 'SET_PARTNER_DEALER_REPORT_DATE_TO'
export const SET_PARTNER_MORE_COMPANIES_IS_LOADING = 'SET_PARTNER_MORE_COMPANIES_IS_LOADING'
export const SET_PARTNER_SHOW_CALENDAR = 'SET_PARTNER_SHOW_CALENDAR'
export const SET_SHOW_PARTNER_DEALERS_CHECKBOX = 'SET_SHOW_PARTNER_DEALERS_CHECKBOX'
export const SET_PARTNER_INCLUDE_PARTNER_DEALERS = 'SET_PARTNER_INCLUDE_PARTNER_DEALERS'
export const SET_PARTNER_PROLONGATION = 'SET_PARTNER_PROLONGATION'
export const SET_PARTNER_DEACTIVATION_STRIPE_DATA = 'SET_PARTNER_DEACTIVATION_STRIPE_DATA'
export const UPDATE_PARTNER_COMPANIES = 'UPDATE_PARTNER_COMPANIES'
export const SET_PARTNER_COMPANY_DATA = 'SET_PARTNER_COMPANY_DATA'
export const SET_PARTNER_COMPANY_INDEX = 'SET_PARTNER_COMPANY_INDEX'

