import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ButtonLogin from './buttonLogin'
import { ButtonsLoginWrapper } from './index.styled'
import { setLoginFailed } from '../../../redux/action'
import { useDispatch } from 'react-redux'
import { isMobileAppMode } from '../../../helper/mode'

const ButtonsLogin = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isMobileApp = isMobileAppMode()

	const navigateToLogin = () => {
		dispatch(setLoginFailed())
		navigate('/login')
	}
	const navigateToRegister = () => {
		dispatch(setLoginFailed())
		navigate('/registration')
	}

	return (
		<ButtonsLoginWrapper>
			<ButtonLogin text={t('Login.Login')} onClick={navigateToLogin} />
			{!isMobileApp &&
				<ButtonLogin text={t('Registration.Owner.Register')} isBorder onClick={navigateToRegister} />}
		</ButtonsLoginWrapper>
	)
}

ButtonsLogin.displayName = 'ButtonsLogin'
export default ButtonsLogin
