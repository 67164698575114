import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomModal from '../CustomModal'
import ModalButton from './ModalButton'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import KeyBindings from '../../keybindings'
import { ButtonsModalWrapper } from './index.styled'

const ButtonsModal = React.memo(({
	isOpen = false, close, title, titlePrice, size, id, enableOutsideClick,
	zIndex = 1, returnZIndex = 0, autoFocus = true, buttons = [], children,
	mobileIndentIsNeeded = false,
}) => {
	const isNeededZIndex = useSelector(selectIsNeededZIndex(zIndex))
	const [activeButtonIndex, setActiveButtonIndex] = useState(0)
	const [onClickIsProcessing, setOnClickIsProcessing] = useState(false)
	const visibleButtons = useMemo(() => buttons.filter(btn => btn.show), [buttons])

	const focusFirstChild = useCallback(() => {
		if (autoFocus) {
			setActiveButtonIndex(0)
		}
	}, [autoFocus])

	useEffect(() => {
		if (isOpen) {
			focusFirstChild()
		}
	}, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleArrowUp = useCallback(() => {
		if (!visibleButtons[activeButtonIndex - 1]) {
			setActiveButtonIndex(visibleButtons.length - 1)
		} else {
			setActiveButtonIndex(activeButtonIndex - 1)
		}
	}, [visibleButtons, activeButtonIndex])

	const handleArrowDown = useCallback(() => {
		if (!visibleButtons[activeButtonIndex + 1]) {
			setActiveButtonIndex(0)
		} else {
			setActiveButtonIndex(activeButtonIndex + 1)
		}
	}, [visibleButtons, activeButtonIndex])

	const handleProcessClick = useCallback(async (button, event = null) => {
		setOnClickIsProcessing(true)
		await (event ? button.onClick(event) : button.onClick())
		setOnClickIsProcessing(false)
	}, [])

	const handleButtonClick = useCallback(async (e, button, index) => {
		setActiveButtonIndex(index)
		await handleProcessClick(button, e)
	}, [handleProcessClick])

	const handleEnter = useCallback(async () => {
		const selectedButton = visibleButtons[activeButtonIndex]
		const isDisabled = selectedButton.disabled || onClickIsProcessing
		if (isDisabled) return
		await handleProcessClick(selectedButton)
	}, [visibleButtons, activeButtonIndex, handleProcessClick, onClickIsProcessing])

	return (
		<>
			<CustomModal {...{
				isOpen, close, title, titlePrice, size, id,
				enableOutsideClick, zIndex, returnZIndex,
			}}>
				<ButtonsModalWrapper className={mobileIndentIsNeeded ? 'mobile-indent' : ''}>
					{children}
					{visibleButtons?.map((button, index) =>
						<ModalButton key={index}
												 index={index}
												 tabIndex={index}
												 button={button}
												 onClick={handleButtonClick}
												 isDisabled={button.disabled || onClickIsProcessing}
												 isActive={index === activeButtonIndex} />)}
				</ButtonsModalWrapper>
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings enter={handleEnter}
										 arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown} />}
		</>
	)
})

ButtonsModal.displayName = 'ButtonsModal'
export default ButtonsModal
