import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

const MODAL_DESKTOP_HEADER = 7.06
const MODAL_DESKTOP_FOOTER = 5.94
const MODAL_BODY_PADDING_BOTTOM = 6.25
const MODAL_MOBILE_FOOTER = 15.31

export const SendToKitchenModalWrapper = styled.div`
	font-size: 1em;
	display: grid;
	grid-template-columns: 1fr min-content 1fr;
	height: calc(100vh - ${MODAL_DESKTOP_HEADER}em - ${MODAL_DESKTOP_FOOTER}em);

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: 1fr;
		height: 100%;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: calc(${MODAL_MOBILE_FOOTER}em - ${MODAL_BODY_PADDING_BOTTOM}em + 1.25em);
	}

`
SendToKitchenModalWrapper.displayName = 'SendToKitchenModalWrapper'
export const SendToKitchenItemsBlock = styled.div`
	height: 100%;
	padding: 1.88em 1.25em 1.25em;
	overflow-y: auto;

	@media screen and (max-width: ${theme.point820}) {
		padding: 1.43em 0 0;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 1.25em 0 0;
	}
`
SendToKitchenItemsBlock.displayName = 'SendToKitchenItemsBlock'
export const SendToKitchenItemsDivider = styled.div`
	height: 100%;
	width: 1.25em;
	background-color: ${colorTheme.restaurant.sendToKitchenModal.divider};

	@media screen and (max-width: ${theme.point820}) {
		height: 1.25em;
		width: 100%;
	}
`
SendToKitchenItemsDivider.displayName = 'SendToKitchenItemsDivider'
export const SendToKitchenItemsHeader = styled.div`
	display: grid;
	padding-bottom: 0.63em;
	border-bottom: 1px solid ${colorTheme.restaurant.sendToKitchenModal.items.header.border};

	&.with-status {
		grid-template-columns: 1.88em 1fr auto;
	}

	&.with-dish-course {
		grid-template-columns: 3.13em 1fr auto;
	}

	&.with-dish-course-and-status {
		grid-template-columns: 2.94em 1.88em 1fr auto;
	}

	@media screen and (max-width: ${theme.point820}) {
		border-bottom: 3px solid ${colorTheme.restaurant.sendToKitchenModal.items.header.tabletBorder};
	}
`
SendToKitchenItemsHeader.displayName = 'SendToKitchenItemsHeader'
export const SendToKitchenHeaderTitle = styled.div`
	color: ${colorTheme.restaurant.sendToKitchenModal.items.header.title};
	font-family: ${theme.fontRobotoMedium};
	font-size: 0.88em;
	font-weight: 500;
	grid-column: 1;

	&.with-status, &.with-dish-course {
		grid-column: 2;
	}

	&.with-dish-course-and-status {
		grid-column: 3;
	}
`
SendToKitchenHeaderTitle.displayName = 'SendToKitchenHeaderTitle'
export const SendToKitchenItems = styled.div`
	display: grid;
`
SendToKitchenItems.displayName = 'SendToKitchenItems'
export const SendToKitchenButtonsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1.25em;
	width: 100%;

	@media screen and (max-width: ${theme.point820}) {
		flex-direction: column;

		button {
			width: 100%;
		}
	}
`
SendToKitchenButtonsWrapper.displayName = 'SendToKitchenButtonsWrapper'
export const SendToKitchenButtonsInnerWrapper = styled.div`
	display: flex;
	gap: 2.5em;
	align-items: center;

	@media screen and (max-width: ${theme.point820}) {
		gap: 1.25em;
		width: 100%;
	}

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column;
	}
`
SendToKitchenButtonsInnerWrapper.displayName = 'SendToKitchenButtonsInnerWrapper'
