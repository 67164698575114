import React from 'react'
import { CartHeaderButtonWrapper } from '../../../Elements/cart/index.styled'
import Button from '../../../Elements/buttons/Button'
import { setDeliveryCartPreorderDate } from '../../../../redux/action/deliveryCart'
import Coupon from '../../../Elements/cart/Coupon'
import MoneyExchange from '../../../Elements/cart/MoneyExchange'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectHasUnpaidOrderInCart } from '../../../../redux/selector/cart'

const EVENT_Z_INDEX = 0
const MODULE = 'DELIVERY'

const CartHeaderButtons = React.memo(({
	addProduct, giftCoupons, isDisabled, hasMoneyExchange, orderType,
	openPreOrderDateModal, openModalCustomProduct, resetDeliveryCost, removeCoupon,
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const preOrderDate = useSelector(store => store.deliveryCart.preOrderDate)
	const isUnpaidOrder = useSelector(selectHasUnpaidOrderInCart(MODULE))

	return (
		<CartHeaderButtonWrapper>
			{preOrderDate ?
				<Button icon="clear" text={t('Cart.Buttons.resetPreOrder')} keyButton="F8" color="red" showTextForTablet={false}
								size="small" zIndex={EVENT_Z_INDEX} onClick={() => dispatch(setDeliveryCartPreorderDate(null))} /> :
				<Button icon="date" text={t('Cart.Buttons.preOrder')} name="add-preorder" keyButton="F8" color="blue" showTextForTablet={false}
								size="small" zIndex={EVENT_Z_INDEX} onClick={openPreOrderDateModal} disabled={hasMoneyExchange || isUnpaidOrder} />}
			<Button icon="plus" text={t('Cart.Buttons.extras')} keyButton="F3" color="blue" showTextForTablet={false}
							size="small" zIndex={EVENT_Z_INDEX} onClick={openModalCustomProduct} disabled={isDisabled} />
			<Button icon="car" text={t('Cart.Buttons.freeTravel')} keyButton="F6" color="blue" showTextForTablet={false}
							size="small" zIndex={EVENT_Z_INDEX} onClick={resetDeliveryCost} />
			<Coupon addCoupon={addProduct} removeCoupon={removeCoupon} disabled={isDisabled || isUnpaidOrder} cartType={MODULE} />
			{orderType !== 'DELIVERY' && <MoneyExchange module={MODULE} giftCoupons={giftCoupons} />}
		</CartHeaderButtonWrapper>
	)
})

CartHeaderButtons.displayName = 'CartHeaderButtons'
export default CartHeaderButtons
