const DesktopMode = {
	CASH_REGISTER: 'CASH_REGISTER',
	KIOSK: 'KIOSK',
}

const MOBILE_APP_MODE = 'LIEFERSOFT_POS_APP'

export const isCashRegisterMode = (desktopMode) => {
	return desktopMode === DesktopMode.CASH_REGISTER
}

export const isLocalStorageCashRegisterMode = () => {
	return window.localStorage.desktopMode === DesktopMode.CASH_REGISTER
}

export const isMobileAppMode = () => {
	return window.localStorage.appMode === MOBILE_APP_MODE
}
