import { getNeededDateFormat } from '../../../helper'

const MS_IN_A_DAY = 1000 * 60 * 60 * 24
export const getDateDifferenceInDays = (deactivationDate, dateTo = new Date()) => {
	if (!deactivationDate) return null

	const deactivationTime = new Date(deactivationDate).getTime()
	const dateToTime = new Date(dateTo).getTime()

	return Math.round((deactivationTime - dateToTime) / MS_IN_A_DAY)
}

export const getActivationData = (companyData, t) => {
	const deactivationDate = companyData?.deactivationDate
	const subscription = companyData?.subscription
	const isTestMode = !subscription?.status
	const dateDifferenceInDays = getDateDifferenceInDays(deactivationDate)
	const isDeactivated = !!deactivationDate && (dateDifferenceInDays <= 0)
	const willBeDeactivated = !!deactivationDate && (dateDifferenceInDays > 0)
	const date = getNeededDateFormat(deactivationDate)
	const emergencyBlockingDate = getNeededDateFormat(companyData?.emergencyBlockingDate)
	if (emergencyBlockingDate) {
		return { text: t('partnerPortal.activation.is_emergency_blocked', { date: emergencyBlockingDate }), className: 'error', prolongation: null }
	} else if (isTestMode && !isDeactivated) {
		return { text: t('partnerPortal.activation.test', { days: dateDifferenceInDays }), className: 'warn', prolongation: { class: 'warn' } }
	} else if (isTestMode && isDeactivated) {
		return { text: t('partnerPortal.activation.test_deactivated', { date }), className: 'info', prolongation: { class: 'info' } }
	} else if (subscription.status === 'UNPAID' && willBeDeactivated) {
		return { text: t('partnerPortal.activation.will_be_deactivated_due_unpaid', { date }), className: 'error', prolongation: null }
	} else if (willBeDeactivated) {
		return { text: t('partnerPortal.activation.will_be_deactivated', { date }), className: 'warn', prolongation: null }
	} else if (subscription.status === 'UNPAID' && isDeactivated) {
		return { text: t('partnerPortal.activation.is_deactivated_due_unpaid', { date }), className: 'error', prolongation: { class: 'error' } }
	} else if (isDeactivated) {
		return { text: t('partnerPortal.activation.is_deactivated', { date }), className: 'info-light', prolongation: { class: 'info' } }
	} else if (subscription.status === 'LIVE') {
		return null
	} else {
		// eslint-disable-next-line no-console
		console.error('Invalid subscription status: ', companyData.index, subscription.status, deactivationDate)
		return { text: '!!! ERROR !!!!', className: 'error' }
	}
}

export const getConditionValue = (conditions, type) => {
	return conditions?.find(condition => condition?.financialDataType === type)?.value ?? 0
}

export const getConditionsValue = (conditions) => {
	const percentsConditionValue = conditions?.find(condition => condition?.financialDataType === 'PERCENTS')?.value ?? 0
	const currencyConditionValue = conditions?.find(condition => condition?.financialDataType === 'CURRENCY')?.value ?? 0
	return conditions ? { percentsConditionValue, currencyConditionValue } : null
}

export const getDeactivationStripeData = (data, t) => {
	const status = data.status
	const companyData = data.companyData
	if (status === 'STARTED') {
		return { text: t('partnerPortal.stripeAccountInfo.deactivationStatus.started', { index: companyData.index, name: companyData.name }), className: '', questionIcon: true }
	} else if (status === 'COMPLETED') {
		return { text: t('partnerPortal.stripeAccountInfo.deactivationStatus.completed', { index: companyData.index, name: companyData.name }), className: '' }
	} else if (status === 'STRIPE_SUM_MORE_THAN_ZERO') {
		return { text: t('partnerPortal.stripeAccountInfo.deactivationStatus.sumMoreThanZero', { index: companyData.index, name: companyData.name }), className: 'warn' }
	} else if (status === 'STRIPE_SUM_LESS_THAN_ZERO') {
		return { text: t('partnerPortal.stripeAccountInfo.deactivationStatus.sumLessThanZero', { index: companyData.index, name: companyData.name }), className: 'warn' }
	} else {
		// eslint-disable-next-line no-console
		console.error('Invalid deactivation stripe status: ', companyData.index, status)
		return { text: '!!! ERROR !!!!', className: 'error' }
	}
}

const getStripeAccountTooltipText = (item, t) => {
	const status = item?.stripeAccount?.status
	const accountDeletedDate = getNeededDateFormat(item?.stripeAccount?.accountDeletionDate)
	const isStripeAccount = !!item?.stripeAccount

	if (status === 'DELETION_REQUESTED') {
		return t('partnerPortal.stripeAccountInfo.deletionRequested')
	} else if (status === 'DELETED') {
		return t('partnerPortal.stripeAccountInfo.deleted', { date: accountDeletedDate })
	} else if (status === 'REJECTED') {
		return t('partnerPortal.stripeAccountInfo.rejected')
	} else if (status === 'RESTRICTED') {
		return t('partnerPortal.stripeAccountInfo.restricted')
	} else if (status === 'RESTRICTED_SOON') {
		return t('partnerPortal.stripeAccountInfo.restrictedSoon')
	} else if (!isStripeAccount) {
		return t('partnerPortal.stripeAccountInfo.notActivated')
	} else {
		return ''
	}
}

export const getStripeAccountData = (item, hasRoleDeactivationStripe, t) => {
	const stripeAccountStatus = item?.stripeAccount?.status
	const stripeAccountDeleted = ['DELETION_REQUESTED', 'DELETED'].includes(stripeAccountStatus)
	const stripeAccountRestricted = ['RESTRICTED', 'RESTRICTED_SOON'].includes(stripeAccountStatus)
	const disabledStripeButton = !item?.stripeAccount || stripeAccountDeleted
	const layout = (disabledStripeButton ? 'disabled' : '') + (stripeAccountStatus === 'REJECTED' ? ' rejected' : '') + (stripeAccountRestricted ? ' restricted' : '')
	const isTooltip = ['REJECTED', 'RESTRICTED', 'RESTRICTED_SOON'].includes(stripeAccountStatus) || !item?.stripeAccount || stripeAccountDeleted
	const tooltipText = getStripeAccountTooltipText(item, t)
	return { title: 'Stripe', status: stripeAccountStatus, value: 'STRIPE', className: layout, isTooltip: isTooltip, tooltipText: tooltipText, disabled: disabledStripeButton || !hasRoleDeactivationStripe }
}
