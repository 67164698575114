import React from 'react'
import { ReactComponent as BackSvg } from './assets/back.svg'
import { ReactComponent as MinusSvg } from './assets/minus.svg'
import { ReactComponent as BasketSvg } from './assets/basket.svg'
import { ReactComponent as CarSvg } from './assets/car.svg'
import { ReactComponent as CheckSvg } from './assets/check.svg'
import { ReactComponent as CheckmarkSvg } from './assets/checkmark.svg'
import { ReactComponent as CounterSvg } from './assets/counter.svg'
import { ReactComponent as DateSvg } from './assets/date.svg'
import { ReactComponent as PaySvg } from './assets/pay.svg'
import { ReactComponent as PhoneSvg } from './assets/phone.svg'
import { ReactComponent as PickupSvg } from './assets/pickup.svg'
import { ReactComponent as PlusSvg } from './assets/plus.svg'
import { ReactComponent as PlusBlueSvg } from './assets/plus_blue.svg'
import { ReactComponent as PrintSvg } from './assets/print.svg'
import { ReactComponent as WatchSvg } from './assets/watch.svg'
import { ReactComponent as ReportSvg } from './assets/report.svg'
import { ReactComponent as HelpSvg } from './assets/help.svg'
import { ReactComponent as UserSvg } from './assets/user.svg'
import { ReactComponent as NoUserSvg } from './assets/no-user.svg'
import { ReactComponent as ClearSvg } from './assets/clear.svg'
import { ReactComponent as FilterSvg } from './assets/filter.svg'
import { ReactComponent as CartSvg } from './assets/cart.svg'
import { ReactComponent as LogoutSvg } from './assets/logout.svg'
import { ReactComponent as BigCarSvg } from './assets/big-car.svg'
import { ReactComponent as TimeSvg } from './assets/time.svg'
import { ReactComponent as TableSvg } from './assets/table.svg'
import { ReactComponent as TableMapSvg } from './assets/table-map.svg'
import { ReactComponent as MapSvg } from './assets/map.svg'
import { ReactComponent as CashdeskSvg } from './assets/cashdesk.svg'
import { ReactComponent as RestaurantSvg } from './assets/table-restaurant.svg'
import { ReactComponent as DeliverySvg } from './assets/delivery.svg'
import { ReactComponent as PickupTransparentSvg } from './assets/pickup-transparent.svg'
import { ReactComponent as CounterTransparentSvg } from './assets/counter-transparent.svg'
import { ReactComponent as UpdateSvg } from './assets/update.svg'
import { ReactComponent as NextSvg } from './assets/next.svg'
import { ReactComponent as DiagramsSvg } from './assets/diagrams.svg'
import { ReactComponent as ArrowLefSvg } from './assets/arrow-left.svg'
import { ReactComponent as OpenIconSvg } from './assets/open_icon.svg'
import { ReactComponent as CloseIconSvg } from './assets/close_icon.svg'
import { ReactComponent as SwapSvg } from './assets/swap.svg'
import { ReactComponent as ShopSvg } from './assets/shop.svg'
import { ReactComponent as SettingsSvg } from './assets/settings.svg'
import { ReactComponent as NavigateSvg } from './assets/navigate.svg'
import { ReactComponent as ExportSvg } from './assets/export.svg'
import { ReactComponent as EyeSvg } from './assets/eye.svg'
import { ReactComponent as CashBookSvg } from './assets/cash-book.svg'
import { ReactComponent as Settings2Svg } from './assets/settings-2.svg'
import { ReactComponent as ArrowDownSvg } from './assets/arrow_down.svg'
import { ReactComponent as WebshopSvg } from './assets/webshop.svg'
import { ReactComponent as ExpandUpSvg } from './assets/expand-up.svg'
import { ReactComponent as ExpandDownSvg } from './assets/expand-down.svg'
import { ReactComponent as SettingsShopSvg } from './assets/settings-shop.svg'
import { ReactComponent as NavigateShopSvg } from './assets/navigate-shop.svg'
import { ReactComponent as DeleteSvg } from './assets/delete.svg'
import { ReactComponent as ActivateShopSvg } from './assets/activate_shop.svg'
import { ReactComponent as DatevSvg } from './assets/datev.svg'
import { ReactComponent as SaveSvg } from './assets/save.svg'
import { ReactComponent as PickupShopSvg } from './assets/pickup-shop.svg'
import { ReactComponent as DeliveryShopSvg } from './assets/delivery-shop.svg'
import { ReactComponent as CloudCheckmarkSvg } from './assets/cloud-checkmark.svg'
import { ReactComponent as WarningSvg } from './assets/warning.svg'
import { ReactComponent as LieferRadiusSvg } from './assets/lieferradius.svg'
import { ReactComponent as LieferZoneSvg } from './assets/lieferzone.svg'
import { ReactComponent as AddZoneSvg } from './assets/add-zone.svg'
import { ReactComponent as SortSvg } from './assets/ico-sort.svg'
import { ReactComponent as CouponSvg } from './assets/coupon.svg'
import { ReactComponent as OnlinePaymentSvg } from './assets/online_payment.svg'
import { ReactComponent as CashBalanceSvg } from './assets/cash-balance.svg'
import { ReactComponent as SendSvg } from './assets/send.svg'
import { ReactComponent as CalculatorSvg } from './assets/calculator.svg'
import { ReactComponent as RetrySvg } from './assets/retry.svg'
import { ReactComponent as GiftSvg } from './assets/gift.svg'
import { ReactComponent as SearchSvg } from './assets/search.svg'
import { ReactComponent as SunSvg } from './assets/sun.svg'
import { ReactComponent as MoonSvg } from './assets/moon.svg'
import { ReactComponent as WebshopInactiveSvg } from './assets/webshop-inactive.svg'
import { ReactComponent as BurstShapeSvg } from './assets/ burst-shape.svg'
import { ReactComponent as PaypalSvg } from './assets/paypal.svg'
import { ReactComponent as PaypalBlueSvg } from './assets/paypal-blue.svg'
import { ReactComponent as StoreSvg } from './assets/store.svg'
import { ReactComponent as CopySvg } from './assets/copy.svg'
import { ReactComponent as DownloadSvg } from './assets/download.svg'
import { ReactComponent as NoPrintReceiptSvg } from './assets/no-print-receipt.svg'
import { ReactComponent as PrintReceiptSvg } from './assets/print-receipt.svg'
import { ReactComponent as ReloadSvg } from './assets/reload.svg'
import { ReactComponent as TriangleSvg } from './assets/triangle.svg'
import { ReactComponent as DishCoursesSvg } from './assets/dish-courses.svg'
import { ReactComponent as CircleCheckmarkSvg } from './assets/circle-checkmark.svg'
import { ReactComponent as SendEmailSvg } from './assets/send-email.svg'
import { ReactComponent as MoneyExchangeSvg } from './assets/money-exchange.svg'
import { ReactComponent as GetCashSvg } from './assets/get-cash.svg'
import { ReactComponent as KeyboardSvg } from './assets/keyboard.svg'
import { ReactComponent as PanSvg } from './assets/pan.svg'
import { ReactComponent as WallSvg } from './assets/wall.svg'
import { ReactComponent as TableRestaurantSvg } from './assets/table-restaurant.svg'
import { ReactComponent as CrossBoldSvg } from './assets/cross-bold.svg'
import { ReactComponent as SaveWithShadowSvg } from './assets/save-with-shadow.svg'

const ButtonIcon = React.memo(({ icon, width, height, fill }) => {
	const svgProps = { className: icon }

	if (fill) {
		svgProps.fill = fill
	}
	if (width) {
		svgProps.width = width
	}
	if (height) {
		svgProps.height = height
	}

	return (
		<>
			{icon === 'back' && <BackSvg {...svgProps} />}
			{icon === 'minus' && <MinusSvg {...svgProps} />}
			{icon === 'basket' && <BasketSvg {...svgProps} />}
			{icon === 'car' && <CarSvg {...svgProps} />}
			{icon === 'check' && <CheckSvg {...svgProps} />}
			{icon === 'checkmark' && <CheckmarkSvg {...svgProps} />}
			{icon === 'counter' && <CounterSvg {...svgProps} />}
			{icon === 'date' && <DateSvg {...svgProps} />}
			{icon === 'pay' && <PaySvg {...svgProps} />}
			{icon === 'phone' && <PhoneSvg {...svgProps} />}
			{icon === 'pickup' && <PickupSvg {...svgProps} />}
			{icon === 'plus' && <PlusSvg {...svgProps} />}
			{icon === 'plus_blue' && <PlusBlueSvg {...svgProps} />}
			{icon === 'print' && <PrintSvg {...svgProps} />}
			{icon === 'watch' && <WatchSvg {...svgProps} />}
			{icon === 'report' && <ReportSvg {...svgProps} />}
			{icon === 'help' && <HelpSvg {...svgProps} />}
			{icon === 'user' && <UserSvg {...svgProps} />}
			{icon === 'no-user' && <NoUserSvg {...svgProps} />}
			{icon === 'clear' && <ClearSvg {...svgProps} />}
			{icon === 'filter' && <FilterSvg {...svgProps} />}
			{icon === 'cart' && <CartSvg {...svgProps} />}
			{icon === 'logout' && <LogoutSvg {...svgProps} />}
			{icon === 'big-car' && <BigCarSvg {...svgProps} />}
			{icon === 'time' && <TimeSvg {...svgProps} />}
			{icon === 'table' && <TableSvg {...svgProps} />}
			{icon === 'table-map' && <TableMapSvg {...svgProps} />}
			{icon === 'map' && <MapSvg {...svgProps} />}
			{icon === 'cashdesk' && <CashdeskSvg {...svgProps} />}
			{icon === 'restaurant' && <RestaurantSvg {...svgProps} />}
			{icon === 'delivery' && <DeliverySvg {...svgProps} />}
			{icon === 'pickup-transparent' && <PickupTransparentSvg {...svgProps} />}
			{icon === 'counter-transparent' && <CounterTransparentSvg {...svgProps} />}
			{icon === 'update' && <UpdateSvg {...svgProps} />}
			{icon === 'next' && <NextSvg {...svgProps} />}
			{icon === 'diagrams' && <DiagramsSvg {...svgProps} />}
			{icon === 'arrow-left' && <ArrowLefSvg {...svgProps} />}
			{icon === 'open_icon' && <OpenIconSvg {...svgProps} />}
			{icon === 'close_icon' && <CloseIconSvg {...svgProps} />}
			{icon === 'swap' && <SwapSvg {...svgProps} />}
			{icon === 'shop' && <ShopSvg {...svgProps} />}
			{icon === 'settings' && <SettingsSvg {...svgProps} />}
			{icon === 'navigate' && <NavigateSvg {...svgProps} />}
			{icon === 'export' && <ExportSvg {...svgProps} />}
			{icon === 'eye' && <EyeSvg {...svgProps} />}
			{icon === 'cash-book' && <CashBookSvg {...svgProps} />}
			{icon === 'settings-2' && <Settings2Svg {...svgProps} />}
			{icon === 'arrow_down' && <ArrowDownSvg {...svgProps} />}
			{icon === 'webshop' && <WebshopSvg {...svgProps} />}
			{icon === 'expand-up' && <ExpandUpSvg {...svgProps} />}
			{icon === 'expand-down' && <ExpandDownSvg {...svgProps} />}
			{icon === 'settings-shop' && <SettingsShopSvg {...svgProps} />}
			{icon === 'navigate-shop' && <NavigateShopSvg {...svgProps} />}
			{icon === 'delete' && <DeleteSvg {...svgProps} />}
			{icon === 'activate_shop' && <ActivateShopSvg {...svgProps} />}
			{icon === 'datev' && <DatevSvg {...svgProps} />}
			{icon === 'save' && <SaveSvg {...svgProps} />}
			{icon === 'pickup-shop' && <PickupShopSvg {...svgProps} />}
			{icon === 'delivery-shop' && <DeliveryShopSvg {...svgProps} />}
			{icon === 'cloud-checkmark' && <CloudCheckmarkSvg {...svgProps} />}
			{icon === 'warning' && <WarningSvg {...svgProps} />}
			{icon === 'lieferradius' && <LieferRadiusSvg {...svgProps} />}
			{icon === 'lieferzone' && <LieferZoneSvg {...svgProps} />}
			{icon === 'add-zone' && <AddZoneSvg {...svgProps} />}
			{icon === 'ico-sort' && <SortSvg {...svgProps} />}
			{icon === 'coupon' && <CouponSvg {...svgProps} />}
			{icon === 'online_payment' && <OnlinePaymentSvg {...svgProps} />}
			{icon === 'cash-balance' && <CashBalanceSvg {...svgProps} />}
			{icon === 'send' && <SendSvg {...svgProps} />}
			{icon === 'calculator' && <CalculatorSvg {...svgProps} />}
			{icon === 'retry' && <RetrySvg {...svgProps} />}
			{icon === 'gift' && <GiftSvg {...svgProps} />}
			{icon === 'search' && <SearchSvg {...svgProps} />}
			{icon === 'sun' && <SunSvg {...svgProps} />}
			{icon === 'moon' && <MoonSvg {...svgProps} />}
			{icon === 'webshop-inactive' && <WebshopInactiveSvg {...svgProps} />}
			{icon === 'burst-shape' && <BurstShapeSvg {...svgProps} />}
			{icon === 'paypal' && <PaypalSvg {...svgProps} />}
			{icon === 'paypal-blue' && <PaypalBlueSvg {...svgProps} />}
			{icon === 'store' && <StoreSvg {...svgProps} />}
			{icon === 'copy' && <CopySvg {...svgProps} />}
			{icon === 'download' && <DownloadSvg {...svgProps} />}
			{icon === 'no-print-receipt' && <NoPrintReceiptSvg {...svgProps} />}
			{icon === 'print-receipt' && <PrintReceiptSvg {...svgProps} />}
			{icon === 'reload' && <ReloadSvg {...svgProps} />}
			{icon === 'triangle' && <TriangleSvg {...svgProps} />}
			{icon === 'dish-courses' && <DishCoursesSvg {...svgProps} />}
			{icon === 'circle-checkmark' && <CircleCheckmarkSvg {...svgProps} />}
			{icon === 'send-email' && <SendEmailSvg {...svgProps} />}
			{icon === 'money-exchange' && <MoneyExchangeSvg {...svgProps} />}
			{icon === 'get-cash' && <GetCashSvg {...svgProps} />}
			{icon === 'keyboard' && <KeyboardSvg {...svgProps} />}
			{icon === 'pan' && <PanSvg {...svgProps} />}
			{icon === 'wall' && <WallSvg {...svgProps} />}
			{icon === 'table-restaurant' && <TableRestaurantSvg {...svgProps} />}
			{icon === 'cross-bold' && <CrossBoldSvg {...svgProps} />}
			{icon === 'save-with-shadow' && <SaveWithShadowSvg {...svgProps} />}
		</>
	)
})

ButtonIcon.displayName = 'ButtonIcon'
export default ButtonIcon
