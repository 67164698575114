/* eslint no-console: off */
import { batch } from 'react-redux'
import postLogin from '../../../axios/registration/auth/login/post'
import { setLoginData, setLoginFailed, setLoginInvalidIndexError, setLoginInvalidPasswordError, setLoginLoading, setLoginSuccess, setLogout, setPageAfterLogin, setRegistrationOwnerPhoneNumber } from '../../../action'
import { toast } from 'react-toastify'
import postLogout from '../../../axios/registration/auth/logout/post'
import postPasswordLess from '../../../axios/registration/auth/passwordless/post'
import getAuthentication from '../../../axios/registration/auth/authentication/get'
import { setAppErrors } from '../../../action/system'
import { retrieveErrorFromApi } from '../../../../helper'
import { disconnectSocketThunk } from '../../socket'
import { kioskUIUrl } from '../../../../urls'
import { getLocalStorageRefreshToken } from '../../../../helper/clientMonitor'
import { setSupportActivationDate, setSupportDeactivationDate } from '../../../action/support'
import { redirectToLogin } from './helper'

export const postLoginThunk = (email, companyIndex, password, recaptchaValue, callbackSuccess) => dispatch => {
	return postLogin({ email, companyIndex, password, recaptchaValue })
		.then(res => {
			const userGroup = res.data.user?.group
			dispatch(setLoginLoading(false))

			if (userGroup === 'KIOSK') {
				let date = new Date()
				date.setTime(date.getTime() + 60000) // 1 minute expiration time
				const expires = date.toUTCString()
				document.cookie = `accessToken=${res.data.accessToken}; Domain=.liefersoft.de; Path=/; SameSite=Lax; Expires=${expires};`
				document.cookie = `refreshToken=${res.data.refreshToken}; Domain=.liefersoft.de; Path=/; SameSite=Lax; Expires=${expires};`
				window.location.href = kioskUIUrl + `?accessToken=${res.data.accessToken}&refreshToken=${res.data.refreshToken}`
			} else {
				dispatch(setLoginData({ email, companyIndex, password: null }))
				dispatch(setLoginSuccess({
					accessToken: res.data.accessToken,
					refreshToken: res.data.refreshToken
				}))
				if (res.data.user?.loginPage) dispatch(setPageAfterLogin(res.data.user.loginPage))
				if (res.data.user?.company?.activationDate) dispatch(setSupportActivationDate(res.data.user?.company?.activationDate))
				if (res.data.user?.company?.deactivationDate) dispatch(setSupportDeactivationDate(res.data.user?.company?.deactivationDate))
			}
			if (typeof callbackSuccess === 'function') callbackSuccess()

		}).catch(error => {
			dispatch(setLoginLoading(false))
			dispatch(setLoginFailed())
			if (error.response.status === 401) {
				dispatch(setLoginInvalidPasswordError(true))
			} else if (error.response.status === 400) {
				dispatch(setLoginInvalidIndexError(true))
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}

export const postLogoutThunk = () => {
	const refreshToken = getLocalStorageRefreshToken()
	
	return (dispatch) => {
		dispatch(postLogout(refreshToken,
			res => {
				new Promise((resolve, reject) => {
					batch(() => {
						// Sockets disconnection:
						dispatch(disconnectSocketThunk())
					})
					resolve()
				}).then(() => {
					redirectToLogin()
					dispatch(setLogout())
				})
			},
			error => {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
				new Promise((resolve, reject) => {
					batch(() => {
						// Sockets disconnection:
						dispatch(disconnectSocketThunk())
					})
					resolve()
				}).then(() => {
					redirectToLogin()
					dispatch(setLogout())
				})
			}))
	}
}

export const postPasswordLessThunk = (passwordLessCodeId, callbackSuccess) => dispatch => {
	dispatch(postPasswordLess(passwordLessCodeId,
		res => {
			batch(() => {
				toast.success('Passwordless authentication successful')
				dispatch(setLoginData({
					email: res.data.user.email,
					companyIndex: null,
					password: null,
				}))
				dispatch(setLoginSuccess({
					accessToken: res.data.accessToken,
					refreshToken: res.data.refreshToken,
				}))
				if (res.data.user?.mobilePhone) dispatch(setRegistrationOwnerPhoneNumber(res.data.user?.mobilePhone))
			})
			if (typeof callbackSuccess === 'function') callbackSuccess()
		},
		error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
			dispatch(setLoginFailed())
		}))
}

export const getAuthenticationThunk = () => async dispatch => {
	console.info('[socket-io] Try to re-authenticate socket')
	try {
		const res = await getAuthentication()
		return res.data.jwt
	} catch (error) {
		console.info('[socket-io] Error while socket re-authentication')
		throw error
	}
}
