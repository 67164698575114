import React from 'react'
import { ReactComponent as SplitSvg } from './assets/split.svg'
import { ReactComponent as TableClosedWhiteSvg } from './assets/table-closed-white.svg'
import { ReactComponent as TableClosedSvg } from './assets/table-closed.svg'
import { ReactComponent as TableOpenGreenSvg } from './assets/table-open.svg'
import { ReactComponent as TableOpenWhiteSvg } from './assets/table-open-white.svg'
import { ReactComponent as TableOpenSvg } from './assets/table-open.svg'
import { ReactComponent as TableMobileSvg } from './assets/table-mobile.svg'

const RestaurantIcon = React.memo(({ icon, height = 20, width = 20, fill, stroke }) => {
	const svgProps = { height, width, fill, stroke, className: icon }

	return (
		<>
			{icon === 'split' && <SplitSvg {...svgProps} />}
			{icon === 'table-closed-white' && <TableClosedWhiteSvg {...svgProps} />}
			{icon === 'table-closed' && <TableClosedSvg {...svgProps} />}
			{icon === 'table-open-green' && <TableOpenGreenSvg {...svgProps} />}
			{icon === 'table-open-white' && <TableOpenWhiteSvg {...svgProps} />}
			{icon === 'table-open' && <TableOpenSvg {...svgProps} />}
			{icon === 'table-mobile' && <TableMobileSvg {...svgProps} />}
		</>
	)
})

RestaurantIcon.displayName = 'RestaurantIcon'
export default RestaurantIcon
