import styled from 'styled-components'
import { theme } from '../../../../../style/theme'

export const ButtonsWrap = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.25em;
	justify-content: space-between;
	align-items: center;
	margin-left: 0;


	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
		gap: 1.25em;
	}
`
ButtonsWrap.displayName = 'ButtonsWrap'
