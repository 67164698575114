import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MainIcon from '../../../../img/svg/main'
import { useDispatch, useSelector } from 'react-redux'
import CustomInfoModal from '../../../Elements/modal/CustomInfoModal'
import whatsapp_icon from '../../../../img/svg/whatsapp_icon_green.svg'
import { QRCodeSVG } from 'qrcode.react'
import Button from '../../../Elements/buttons/Button'
import { selectCompanyData, selectCompanyIndex } from '../../../../redux/selector/system'
import { setSupportShowEmergencyBlockingModal, setSupportShowExtraContactsModal } from '../../../../redux/action/support'
import { DeactivationModalContacts, DeactivationModalContent, DeactivationModalInfo, DeactivationModalLink, DeactivationModalSub, DeactivationModalText, DeactivationModalWatsappContacts, DeactivationModalWrap } from '../deactivationModal/index.styled'
import { postLogoutThunk } from '../../../../redux/thunk/registration/auth'
import { selectSupportPhone, selectSupportWhatsappHref } from '../../../../redux/selector/support'
import { selectActivationData } from '../../../../redux/selector/company'

const EVENT_Z_INDEX = 70

const EmergencyBlockingModal = React.memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const companyIndex = useSelector(selectCompanyIndex)
	const name = useSelector(selectCompanyData('name'))
	const showModal = useSelector(store => store.support.showEmergencyBlockingModal)
	const whatsappHref = useSelector(selectSupportWhatsappHref)
	const phone = useSelector(selectSupportPhone)
	const { emergencyBlockingType } = useSelector(selectActivationData)
	const isUnpaid = emergencyBlockingType === 'UNPAID'

	useEffect(() => {
		dispatch(setSupportShowEmergencyBlockingModal(true))

		return () => dispatch(setSupportShowEmergencyBlockingModal(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback(() => {
	}, [])

	const handleLogout = useCallback(() => {
		dispatch(setSupportShowEmergencyBlockingModal(false))
		dispatch(postLogoutThunk())
	}, [dispatch])

	const showExtraContacts = useCallback(() => {
		dispatch(setSupportShowExtraContactsModal(true))
	}, [dispatch])

	return (
		<CustomInfoModal isOpen={showModal}
										 title={t('app.modals.Attention')}
										 close={handleClose}
										 zIndex={EVENT_Z_INDEX}
										 className={!whatsappHref && 'bottom-middle'}
										 modalColor="error"
										 toFront={true}
										 button={<>
											 <DeactivationModalLink className="center" onClick={showExtraContacts}>{t('app.modals.extraContacts')}</DeactivationModalLink>
											 <Button icon="logout" onClick={handleLogout} tabIndex="1" text={t('LeftBar.menu.Logout')} color="blue" />
										 </>}>
			<DeactivationModalWrap>
				<div>
					<DeactivationModalText>{isUnpaid ? t('app.modals.emergencyUnpaidBlockingTitle') : t('app.modals.emergencyOtherBlockingTitle')}</DeactivationModalText>
					<DeactivationModalText>{isUnpaid ? t('app.modals.emergencyUnpaidBlockingText') : t('app.modals.emergencyOtherBlockingText')}</DeactivationModalText>
					<DeactivationModalText>{name} - {companyIndex}</DeactivationModalText>
				</div>
				<DeactivationModalContent>
					{whatsappHref &&
						<QRCodeSVG value={whatsappHref}
											 level="M"
											 imageSettings={{
												 src: whatsapp_icon,
												 width: 32,
												 height: 32,
												 x: null,
												 y: null,
												 excavate: true,
											 }} />}
					<DeactivationModalInfo>
						<MainIcon icon={whatsappHref ? 'qr-phone' : 'phone'} />
						{whatsappHref ?
							<DeactivationModalWatsappContacts>
								<DeactivationModalSub>{t('app.modals.warningWatsappText')}</DeactivationModalSub>
								<DeactivationModalSub>{t('app.modals.warningContactsText')}:</DeactivationModalSub>
								<DeactivationModalLink href={`tel:${phone}`}>{phone}</DeactivationModalLink>
							</DeactivationModalWatsappContacts> :
							<DeactivationModalContacts>
								<DeactivationModalSub>{t('app.modals.warningContactsText')}:</DeactivationModalSub>
								<DeactivationModalLink href={`tel:${phone}`}>{phone}</DeactivationModalLink>
							</DeactivationModalContacts>}
					</DeactivationModalInfo>
				</DeactivationModalContent>
			</DeactivationModalWrap>
		</CustomInfoModal>
	)
})

EmergencyBlockingModal.displayName = 'EmergencyBlockingModal'
export default EmergencyBlockingModal
