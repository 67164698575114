import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const ButtonsWrapper = styled.div`
	width: 100%;
	font-size: 1rem;
	display: flex;
	gap: 1.25em;
	align-items: center;
	margin-bottom: 1.875em;

	@media screen and (max-width: ${theme.point820}) {
		flex-direction: column-reverse;
		margin-bottom: 0;
		padding: 0.63em;
	}
`
ButtonsWrapper.displayName = 'ButtonsWrapper'

export const PrintReceiptConfirmationWrap = styled.div`
	width: 37.5em;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: ${theme.point820}) {
		width: auto;
		margin: 0 0.63em;
		height: calc(100vh - 14.2em);
	}
`
PrintReceiptConfirmationWrap.displayName = 'PrintReceiptConfirmationWrap'

export const PrintReceiptConfirmationContent = styled.div`
	@media screen and (max-width: ${theme.point820}) {
		display: grid;
		grid-template-columns: 1fr;
		align-content: center;
		justify-items: center;
		gap: 1.57em;
		width: 100%;
		height: 100%;

		> svg {
			width: 11.28em;
			height: 11.42em;
			margin-bottom: 1.28em;
		}

		> label {
			width: 100%;
			margin-top: 2.85em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 1.37em;

		> svg {
			width: 9.87em;
			height: 10em;
			margin-bottom: 1.12em;
		}

		> label {
			margin-top: 2.5em;
		}
	}
`
PrintReceiptConfirmationContent.displayName = 'PrintReceiptConfirmationContent'

export const PrintReceiptConfirmationTitle = styled.div`
	color: ${colorTheme.basic.textP};
	text-align: center;
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.72em;
	font-weight: 500;

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.5em;
	}
`
PrintReceiptConfirmationTitle.displayName = 'PrintReceiptConfirmationTitle'