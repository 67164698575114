import styled, { css, keyframes } from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

const shadowPulse = (color) => keyframes`
	0%, 100% {
		background: transparent;
	}
	50% {
		background: ${color};
	}
`
export const AreaButtonWrap = styled.div`
	position: relative;

	display: flex;
	padding: .63em 1.25em;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	background: transparent;

	&.shadowPulse {
		animation: ${props => css`${shadowPulse(props.color)} 1.2s ease-in-out infinite alternate`};
	}

	@media screen and (max-width: ${theme.point720}) {
		margin: 0 -.63em;
		align-items: normal;
		align-self: normal;

		&.active {
			margin: 0;
			padding: .31em;
			border-radius: 0.75em;
			flex: 1 0 0;
			background: ${props => props.color};

			&.shadowPulse {
				animation: none;
			}
		}

	}
`
AreaButtonWrap.displayName = 'AreaButtonWrap'

export const AreaButton = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	font-size: 1rem;
	background-color: ${colorTheme.restaurant.area.button.background};
	border: 1px solid ${props => props.color};
	border-radius: 0.75em;
	justify-content: center;
	gap: 0.63em;
	align-items: center;
	color: ${colorTheme.restaurant.area.button.text};
	cursor: pointer;
	min-height: 2.69em;
	padding: 0.5em;

	&.active {
		border: none;
		background-color: ${colorTheme.restaurant.area.button.active.background};
		box-shadow: 0 .38em .38em 0 rgba(0, 0, 0, 0.75);
		border: 2px solid ${props => props.color};
	}

	&.waiter-call {
		border: 3px solid ${colorTheme.restaurant.area.waiterCall.background};
	}


	@media screen and (max-width: ${theme.point720}) {
		padding: 0.75em 2em 0.75em 0.75em;
		gap: 0.56em;
		justify-content: start;
		position: relative;
		border: 2px solid ${props => props.color};

		> svg {
			position: absolute;
			width: 1.13em;
			height: 0.63em;
			right: 1.19em;
			fill: ${colorTheme.restaurant.area.button.mobile.icon};
		}

		&.active {
			flex-shrink: 0;
			background-color: ${colorTheme.restaurant.area.button.active.mobile.background};
			box-shadow: 0 4px 55px rgba(0, 0, 0, 0.3);

			&::after {
				transform: rotate(180deg);
				top: calc(50%);
			}
		}
	}
`
AreaButton.displayName = 'AreaButton'

export const AreaButtonCircle = styled.div`
	font-size: 1rem;
	width: 1.4em;
	height: 1.4em;
	border-radius: 50%;
	background: ${props => props.color};
	border: 1px solid transparent;
	flex-shrink: 0;

	@media screen and (max-width: ${theme.point720}) {
		width: 1.94em;
		height: 1.94em;

		&.active {
			border: none;
		}
	}
`
AreaButtonCircle.displayName = 'AreaButtonCircle'

export const AreaButtonName = styled.div`
	display: flex;
	align-items: center;
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.restaurant.area.button.name};
	text-align: left;
	font-size: 1.06em;
	word-break: break-all;

	&.black {
		color: ${colorTheme.restaurant.area.button.nameBlack};
		font-family: ${theme.fontRobotoRegular};
	}

	&.active {
		font-family: ${theme.fontRobotoBold};
		color: ${colorTheme.restaurant.area.button.active.name};
	}

	@media screen and (max-width: ${theme.point720}) {

		&, &.active, &.black {
			font-size: 1.25em;
			font-family: ${theme.fontRobotoMedium};
			line-height: 1em;
		}
	}
`
AreaButtonName.displayName = 'AreaButtonName'

export const AreaButtonCount = styled.div`
	font-size: 0.75em;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 2em;
	height: 2em;
	padding: 0 0.33em;
	border-radius: 0.5em;
	color: ${colorTheme.restaurant.area.button.count.text};
	font-family: ${theme.fontRobotoBold};
	line-height: 1.17em;
	background: ${colorTheme.restaurant.area.button.count.background};

	&.active {
		background: ${colorTheme.restaurant.area.button.active.count.background};
	}

	&.waiter-call {
		background: ${colorTheme.restaurant.area.waiterCall.background};
	}

	@media screen and (max-width: ${theme.point720}) {
		display: none;
	}
`
AreaButtonCount.displayName = 'AreaButtonCount'
