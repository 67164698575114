export const SET_SUPPORT_DEALER_CONTACTS = 'SET_SUPPORT_DEALER_CONTACTS'
export const SET_SUPPORT_ACTIVATION_DATE = 'SET_SUPPORT_ACTIVATION_DATE'
export const SET_SUPPORT_DEACTIVATION_DATE = 'SET_SUPPORT_DEACTIVATION_DATE'
export const SET_SUPPORT_SHOW_DEACTIVATION_WARNING_MODAL = 'SET_SUPPORT_SHOW_DEACTIVATION_WARNING_MODAL'
export const SET_SUPPORT_SHOW_EXTRA_CONTACTS_MODAL = 'SET_SUPPORT_SHOW_EXTRA_CONTACTS_MODAL'
export const SET_SUPPORT_SHOW_DEACTIVATION_MODAL = 'SET_SUPPORT_SHOW_DEACTIVATION_MODAL'
export const SET_SUPPORT_SHOW_TSE_NOTIFICATION_MODAL = 'SET_SUPPORT_SHOW_TSE_NOTIFICATION_MODAL'
export const SET_SUPPORT_SHOW_BILL_UNPAID_WARNING_MODAL = 'SET_SUPPORT_SHOW_BILL_UNPAID_WARNING_MODAL'
export const SET_SUPPORT_SHOW_BILL_UNPAID_MODAL = 'SET_SUPPORT_SHOW_BILL_UNPAID_MODAL'
export const SET_SUPPORT_SHOW_EMERGENCY_BLOCKING_MODAL = 'SET_SUPPORT_SHOW_EMERGENCY_BLOCKING_MODAL'
