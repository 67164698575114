import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const SvgTableWrap = styled.div`
	position: relative;
	height: ${props => props.height}px;
	width: ${props => props.width}px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -10px;

	> svg {
		position: absolute;
		height: ${props => props.height}px;
		width: ${props => props.width}px;

		&.vertical {
			transform: rotate(90deg);
			height: ${props => props.width}px;
			width: ${props => props.height}px;
		}

		.hidden {
			visibility: hidden;
		}
	}

	&.sizeWithEm {
		height: ${props => props.height}em;
		width: ${props => props.width}em;
		margin-left: -.63em;


		> svg {
			position: absolute;
			height: ${props => props.height}em;
			width: ${props => props.width}em;

			&.vertical {
				transform: rotate(90deg);
				height: ${props => props.width}em;
				width: ${props => props.height}em;
			}

			.hidden {
				visibility: hidden;
			}
		}
	}

	&.round {
		margin: -1.25em;
	}

	&.red .colorTable {
		fill: ${colorTheme.restaurantSettings.table.red};
	}

	&.gray .colorTable {
		fill: ${colorTheme.restaurantSettings.table.gray};
	}

	@keyframes scalePulse {
		0%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.2);
		}
	}

	&.scalePulse {
		animation: scalePulse 0.8s ease-in-out infinite;
	}
`
SvgTableWrap.displayName = 'SvgTableWrap'
export const TableData = styled.div`
	display: grid;
	grid-template-rows: max-content 1.88em;
	justify-items: center;
	align-content: space-between;
	gap: .63em;
	font-size: 1rem;
	height: 55%;
	max-height: 8em;
	z-index: 1;
	color: ${colorTheme.restaurantSettings.table.title};
	flex-shrink: 0;

	&.icon {
		grid-template-rows: max-content;
	}

	&.vertical {
		height: auto;
	}
`

TableData.displayName = 'TableData'

export const TableDataNumber = styled.div`
	color: ${colorTheme.restaurantSettings.table.title}
	text-align: center;
	font-family: ${theme.fontRoboto};
	font-size: 1em;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`
TableDataNumber.displayName = 'TableDataNumber'

export const TableDataIconWrap = styled.div`
	cursor: pointer;
	font-size: 1rem;
	margin-top: .3em;

	> svg {
		width: 2.25em;
		height: 2.25em;
	}

	&.qr-code svg {
		width: 2.25em;
		height: 2.25em;
	}

	&.small.qr-code svg {
		width: 2em;
		height: 2em;
	}
`
TableDataIconWrap.displayName = 'TableDataIconWrap'

export const TableDataPrice = styled.div`
	color: ${colorTheme.restaurantSettings.table.title};
	font-family: ${theme.fontRoboto};
	font-size: 1.25em;
	font-weight: 700;
	padding: .25em .44em;

	&.readyFood {
		font-size: 1em;
	}
`
TableDataPrice.displayName = 'TableDataPrice'

export const TableDataPriceWrap = styled.div`
	font-size: 1em;
	border-radius: .88em;
	border: 1px solid ${colorTheme.restaurantSettings.table.green};
	background: ${colorTheme.restaurantSettings.table.backgroundPrice};
	display: flex;
	justify-content: center;
	align-items: center;
`
TableDataPriceWrap.displayName = 'TableDataPriceWrap'

export const TableDataWaiter = styled.div`
	width: 100%;
	height: 100%;
	font-size: .88em;
	word-break: break-word;
	color: ${colorTheme.restaurantSettings.table.title};
`
TableDataWaiter.displayName = 'TableDataWaiter'


export const TableDataWaiterWrap = styled.div`
	font-size: 1rem;
	width: 7.25em;
	height: fit-content;
	padding: .13em .25em;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	border-radius: .33em;
	border: 1px solid ${colorTheme.restaurantSettings.table.title};
	text-align: center;
	background: ${colorTheme.restaurantSettings.table.backgroundWaiterData};

	&.mg-t {
		margin-top: .6em;
	}

	&.rectangle:not(.vertical),
	&.bar_counter:not(.vertical) {
		width: max-content;
	}
`
TableDataWaiterWrap.displayName = 'TableDataWaiterWrap'
export const TableDataContent = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-rows: max-content 1.94em;
	justify-items: center;
	align-content: center;
	flex-grow: 1;

	&.icon {
		grid-template-rows: max-content 2.8em;
	}

`
TableDataContent.displayName = 'TableDataContent'
