export const RE_INITIALIZE_RESTAURANT = 'RE_INITIALIZE_RESTAURANT'
export const SET_RESTAURANT_AREAS = 'SET_RESTAURANT_AREAS'
export const SET_RESTAURANT_TABLES = 'SET_RESTAURANT_TABLES'
export const UPDATE_RESTAURANT_TABLE = 'UPDATE_RESTAURANT_TABLE'
export const SET_RESTAURANT_TABLES_LOADING = 'SET_RESTAURANT_TABLES_LOADING'
export const SET_RESTAURANT_AREA_ID = 'SET_RESTAURANT_AREA_ID'
export const SET_RESTAURANT_TABLE = 'SET_RESTAURANT_TABLE'
export const SET_RESTAURANT_TABLE_MODAL = 'SET_RESTAURANT_TABLE_MODAL'
export const SET_RESTAURANT_PAYMENT_MODAL = 'SET_RESTAURANT_PAYMENT_MODAL'
export const SET_RESTAURANT_PAYMENT_TYPE_MODAL = 'SET_RESTAURANT_PAYMENT_TYPE_MODAL'
export const SET_RESTAURANT_SWAP_MODAL = 'SET_RESTAURANT_SWAP_MODAL'
export const SET_RESTAURANT_SPLIT_MODAL = 'SET_RESTAURANT_SPLIT_MODAL'
export const SET_RESTAURANT_SPLIT_ACTIONS_MODAL = 'SET_RESTAURANT_SPLIT_ACTIONS_MODAL'
export const SET_RESTAURANT_ORDER_MODAL = 'SET_RESTAURANT_ORDER_MODAL'
export const SET_RESTAURANT_ORDER_SPLIT_MODAL = 'SET_RESTAURANT_ORDER_SPLIT_MODAL'
export const SET_RESTAURANT_ORDERS = 'SET_RESTAURANT_ORDERS'
export const SET_RESTAURANT_ORDERS_MOVE = 'SET_RESTAURANT_ORDERS_MOVE'
export const APPEND_RESTAURANT_ORDERS = 'APPEND_RESTAURANT_ORDERS'
export const ADD_NEW_RESTAURANT_ORDER = 'ADD_NEW_RESTAURANT_ORDER'
export const UPDATE_RESTAURANT_ORDER = 'UPDATE_RESTAURANT_ORDER'
export const REMOVE_RESTAURANT_ORDER = 'REMOVE_RESTAURANT_ORDER'
export const SET_RESTAURANT_ORDERS_TOTAL = 'SET_RESTAURANT_ORDERS_TOTAL'
export const UPDATE_RESTAURANT_ORDER_STATUS = 'UPDATE_RESTAURANT_ORDER_STATUS'
export const SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_NEEDED = 'SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_NEEDED'
export const SET_RESTAURANT_TABLE_CLOSED_ERROR_MODAL = 'SET_RESTAURANT_TABLE_CLOSED_ERROR_MODAL'
export const SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_OPEN = 'SET_RESTAURANT_MODAL_CHANGE_CALCULATOR_IS_OPEN'
export const SET_RESTAURANT_MODAL_TERMINAL_BUTTONS_IS_OPEN = 'SET_RESTAURANT_MODAL_TERMINAL_BUTTONS_IS_OPEN'
export const SET_RESTAURANT_MODAL_TERMINAL_PAYMENT_PROCESS_IS_OPEN = 'SET_RESTAURANT_MODAL_TERMINAL_PAYMENT_PROCESS_IS_OPEN'
export const SET_RESTAURANT_MODAL_INVOICE_CONFIGS_IS_OPEN = 'SET_RESTAURANT_MODAL_INVOICE_CONFIGS_IS_OPEN'
export const SET_RESTAURANT_MODAL_INVOICE_CUSTOMER_DATA_IS_OPEN = 'SET_RESTAURANT_MODAL_INVOICE_CUSTOMER_DATA_IS_OPEN'
export const SET_RESTAURANT_COUPON_ACTIVATE_MODAL = 'SET_RESTAURANT_COUPON_ACTIVATE_MODAL'
export const SET_RESTAURANT_ORDER_CHANGE_MODAL = 'SET_RESTAURANT_ORDER_CHANGE_MODAL'
export const SET_RESTAURANT_ORDERS_CHANGE_MODAL = 'SET_RESTAURANT_ORDERS_CHANGE_MODAL'
export const SET_RESTAURANT_SHOW_PROCESS_PAYMENT_ERROR_MODAL = 'SET_RESTAURANT_SHOW_PROCESS_PAYMENT_ERROR_MODAL'
export const SET_RESTAURANT_TABLES_WAITER_CALLS = 'SET_RESTAURANT_TABLES_WAITER_CALLS'
export const ADD_RESTAURANT_TABLES_WAITER_CALL = 'ADD_RESTAURANT_TABLES_WAITER_CALL'
export const SET_RESTAURANT_SHOW_SEND_TO_KITCHEN_MODAL = 'SET_RESTAURANT_SHOW_SEND_TO_KITCHEN_MODAL'
export const SET_RESTAURANT_SHOW_TABLE_WAITER_NOTIFIED_MODAL = 'SET_RESTAURANT_SHOW_TABLE_WAITER_NOTIFIED_MODAL'
export const SET_RESTAURANT_IS_ANIMATION_STOPPED = 'SET_RESTAURANT_IS_ANIMATION_STOPPED'
