import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import IncomePhoneTable from './table'
import { setAppEventZIndex, setAppModal } from '../../../redux/action/system'
import { setAutocompleteGeo, setCustomer, setCustomerIncomingCall, setCustomersByPhone, setEventOrderCustomer } from '../../../redux/action/customer'
import CustomModal from '../../Elements/modal/CustomModal'
import Button from '../../Elements/buttons/Button'
import { IncomePhoneModalWrapper } from './index.styled'
import { notifyToast } from '../../../helper'
import IncomingCall from './IncomingCall'
import KeyBindings from '../../Elements/keybindings'
import { selectIsNeededZIndex } from '../../../redux/selector/system'
import { useLocation, useNavigate } from 'react-router-dom'
import { searchCustomersByPhoneThunk } from '../../../redux/thunk/customer'

const EVENT_Z_INDEX = 999

const IncomePhoneModal = React.memo(() => {
	const { t } = useTranslation()
	const incomeModalShow = useSelector(store => store.system.modal?.incomeModalShow)
	const isCustomerCalling = useSelector(state => state.customerStore.incomingCall)
	const customersByPhone = useSelector(state => incomeModalShow && state.customerStore?.customersByPhone)
	const customerCalls = useSelector(state => state.customerStore.calls)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const formChanged = useSelector(store => store.system.formChanged)
	const formChangedModal = useSelector(store => store.system.formChangedModal)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const [activeRow, setActiveRow] = useState(0)
	const callsCount = customerCalls.length
	const shouldNotShowModal = (['/cashdesk', '/delivery/cart', '/restaurant/cart'].includes(location.pathname))

	useEffect(() => {
		if (incomeModalShow && customersByPhone) {
			getCustomers(customersByPhone)
			dispatch(setCustomersByPhone(null))
		}
	}, [incomeModalShow, customersByPhone]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback(() => {
		dispatch(setAppModal({ incomeModalShow: false }))
		dispatch(setCustomerIncomingCall(null))
		setActiveRow(0)
		dispatch(setEventOrderCustomer({ eventForm: true }))
		dispatch(setEventOrderCustomer({ eventTable: true }))
	}, [dispatch])

	const getCustomers = useCallback((customers) => {
		if (!customers) return
		if (customers.length >= 1) { // go if exactly one matched
			if (!customers[0].geoData) {
				dispatch(setCustomer(customers[0]))
				dispatch(setAutocompleteGeo(true))
				dispatch(setAppEventZIndex(7))
				handleClose()
			} else {
				dispatch(setCustomer(customers[0]))
				dispatch(setAutocompleteGeo(false))
				handleClose()
			}
			if (location.pathname !== '/delivery' && !formChanged && !formChangedModal) {
				navigate('/delivery')
			}
		} else if (customers.length === 0) { // go if none found
			dispatch(setCustomer({ phoneNumber: customerCalls[activeRow]?.customerPhone }))
			handleClose()
			if (location.pathname !== '/delivery' && !formChanged && !formChangedModal) {
				navigate('/delivery')
			}
		}
	}, [location.pathname, activeRow, dispatch, formChangedModal, formChanged, handleClose, navigate, customerCalls])

	const handleArrowUp = useCallback(() => {
		if ((activeRow - 1) < 0) {
			setActiveRow(callsCount - 1)
		} else {
			setActiveRow(activeRow - 1)
		}
	}, [activeRow, callsCount])

	const handleArrowDown = useCallback(() => {
		if ((activeRow + 1) >= callsCount) {
			setActiveRow(0)
		} else {
			setActiveRow(activeRow + 1)
		}
	}, [activeRow, callsCount])

	const handleEnter = useCallback(() => {
		notifyToast({ message: 'Verwenden Sie Leertaste anstelle von Enter' }, 'warning')
	}, [])

	const pickUpPhone = useCallback((customer) => {
		if (!customer.customerPhone) return
		dispatch(setAppEventZIndex(0))
		dispatch(searchCustomersByPhoneThunk(customer.customerPhone))
	}, [dispatch])

	const handleActiveElement = useCallback(() => {
		pickUpPhone(customerCalls[activeRow])
	}, [customerCalls, activeRow, pickUpPhone])

	const handleTableRowClick = useCallback((index) => {
		setActiveRow(index)
	}, [])

	return (
		<>
			<CustomModal isOpen={incomeModalShow && !shouldNotShowModal}
									 close={handleClose}
									 title={t('Modal.incoming_call.title')}
									 size="x-small"
									 dividedFooterLine={true}
									 disableBackButton={true}
									 mobileFooter={true}
									 zIndex={EVENT_Z_INDEX}
									 button={
										 <>
											 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel')}
															 keyButton="ESC" zIndex={EVENT_Z_INDEX}
															 onClick={handleClose}
															 className="back" />
											 <Button name="accept" icon="checkmark" color="green"
															 keyButton="Space" zIndex={EVENT_Z_INDEX}
															 onClick={handleActiveElement}
															 text={t('buttons.controls.accept')} />
										 </>
									 }>
				<IncomePhoneModalWrapper>
					{isCustomerCalling &&
						<IncomingCall onDoubleClick={pickUpPhone} />}
					<IncomePhoneTable activeRowIndex={activeRow}
														onRowClick={handleTableRowClick}
														onRowDoubleClick={pickUpPhone} />
				</IncomePhoneModalWrapper>
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown}
										 enter={handleEnter} />}
		</>
	)
})

IncomePhoneModal.displayName = 'IncomePhoneModal'
export default IncomePhoneModal
