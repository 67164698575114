import React from 'react'
import DeactivationModal from './deactivationModal'
import { useSelector } from 'react-redux'
import { selectActivationData } from '../../../redux/selector/company'
import { selectIsAuthorized } from '../../../redux/selector/system'
import DeactivationWarningModal from './deactivationWarningModal'
import BillUnpaidWarningModal from './billUnpaidWarningModal'
import BillUnpaidModal from './billUnpaidModal'
import EmergencyBlockingModal from './emergencyBlockingModal'

const DeactivationModals = React.memo(() => {
	const isAuthorized = useSelector(selectIsAuthorized)
	const { isTestMode, isDeactivated, dateDifferenceInDays, isUnpaid, isEmergencyBlocked } = useSelector(selectActivationData)

	if (!isAuthorized) {
		return null
	} else if (isTestMode && isDeactivated) {
		return <DeactivationModal />
	} else if (isTestMode && dateDifferenceInDays > 0 && dateDifferenceInDays < 15) {
		return <DeactivationWarningModal />
	} else if (isUnpaid && !isDeactivated && dateDifferenceInDays < 8) {
		return <BillUnpaidWarningModal />
	} else if (isUnpaid && isDeactivated) {
		return <BillUnpaidModal />
	} else if (isDeactivated) {
		return <DeactivationModal />
	} else if (isEmergencyBlocked) {
		return <EmergencyBlockingModal />
	} else {
		return null
	}
})

DeactivationModals.displayName = 'DeactivationModals'
export default DeactivationModals
