import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const ButtonsModalWrapper = styled.div`
	font-size: 1rem;

	@media screen and (max-width: ${theme.point720}) {
		&.mobile-indent {
			margin-bottom: 5em;
		}
	}
`
ButtonsModalWrapper.displayName = 'ButtonsModalWrapper'

export const ModalButtonContent = styled.div`
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.46em;
	margin-bottom: 0.46em;
`
ModalButtonContent.displayName = 'ModalButtonContent'

export const ModalButtonWrap = styled.button`
	background: ${colorTheme.modal.buttons.background};
	border-radius: 0.55em;
	display: flex;
	align-items: center;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 1.18em;
	padding: 0.23em 1.09em;
	text-align: left;
	border: 2px solid ${colorTheme.modal.buttons.border};
	color: ${colorTheme.modal.buttons.text};
	min-height: 3.05em;
	cursor: pointer;
	position: relative;
	width: 100%;
	gap: 1em;
	transition: all 0.3s ease;

	> svg {
		width: 1.28em;
		height: 1.28em;
		fill: ${colorTheme.modal.buttons.icon};
		flex-shrink: 0;
	}

	&:hover {
		border: 2px solid ${colorTheme.modal.buttons.borderHover};
		box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
	}

	&.active {
		background: ${colorTheme.modal.buttons.active.background};
		border-color: ${colorTheme.modal.buttons.active.border};
		color: ${colorTheme.modal.buttons.active.text};

		> svg {
			fill: ${colorTheme.modal.buttons.active.icon};
		}

		&:after {
			display: block;
			content: url("data:image/svg+xml,%3Csvg viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.0041 0.552342L8.10471 13.3952L3.01103 7.76817C2.36673 7.05697 1.27001 7.00592 0.562336 7.65727C-0.141819 8.30861 -0.19199 9.41501 0.453192 10.1271L6.81259 17.1493C7.05112 17.4116 7.35831 17.5938 7.69454 17.673C7.82041 17.7012 7.94892 17.7179 8.08007 17.7179C8.56418 17.7214 9.02452 17.5207 9.35635 17.166L22.5337 2.94119C23.186 2.23615 23.1499 1.13063 22.451 0.471364C21.7556 -0.18614 20.658 -0.152693 20.0041 0.552342Z' fill='white'/%3E%3C/svg%3E%0A");
			position: absolute;
			top: calc(50% - 0.5em);
			right: 1.23em;
			width: 1em;
			height: 1em;
		}

		&.disabled {
			background: ${colorTheme.modal.buttons.active.disabled.background};
			border-color: ${colorTheme.modal.buttons.active.disabled.border};
			color: ${colorTheme.modal.buttons.active.disabled.text};

			> svg {
				fill: ${colorTheme.modal.buttons.active.disabled.icon};
			}
		}

		.hide-on-active {
			visibility: hidden;
		}
	}

	&.disabled {
		cursor: not-allowed;
		background: ${colorTheme.modal.buttons.disabled.background};
		border-color: ${colorTheme.modal.buttons.disabled.border};
		color: ${colorTheme.modal.buttons.disabled.text};

		> svg {
			fill: ${colorTheme.modal.buttons.disabled.icon};
		}
	}


	@media screen and (max-width: ${theme.point720}) {
		min-height: 2.5em;
		margin-bottom: 0.9em;
	}
`
ModalButtonWrap.displayName = 'ModalButtonWrap'
